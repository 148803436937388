<template>
  <div class="test">
    <div class="test-type">
      <div class="pic">
        <img src="../images/pic-hearing.png">
      </div>
      <div class="test-type__title">
        <p class="title">
          {{ product.productName }}
        </p>
        <!-- <p class="text">
          {{ product.productName }}
        </p> -->
      </div>
    </div>
    <div class="test-index text-center">
      第<span class="primary-c">{{ other.index +1 }}</span>/{{ other.len }}题目
    </div>
    <div class="test-title">
      <div v-html="question.prductItemName" />
    </div>
    <p class="test-text question">
      <img src="../images/finger.svg">
      {{ option.itemOptionsChar }}：{{ option.itemOptionsName }}
    </p>
    <div class="test-video text-center">
      <!-- <img src="../images/vd.png"> -->
      <Player :url="option.itemOptionsVideoUrl" />
    </div>
    <div class="test-answer">
      <div
        class="test-answer__item"
        @click="select( true)"
      >
        <span
          class="circle"
          :class="{select:option.isSelect}"
        >
          <i class="el-icon-check" />
        </span> 是
      </div>
      <div
        class="test-answer__item"
        @click="select(false)"
      >
        <span
          class="circle"
          :class="{select:option.isSelect===false}"
        >
          <i class="el-icon-check" />
        </span>否
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    Player: () => import('@/components/Player/index.vue')
  },
  props: {
    // 产品
    product: {
      type: Object,
      default: () => ({})
    },
    // 题目信息
    question: {
      type: Object,
      default: () => ({})
    },
    // 选项信息
    option: {
      type: Object,
      default: () => ({})
    },
    // 其他信息
    other: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    noValueAndLast() {
      return this.other.index + 1 >= this.other.len && (!this.question.selectValue || this.question.selectValue === this.option.itemOptionsChar);
    }
  },
  mounted() {
    if (this.noValueAndLast) {
      this.select(true, 'last');
    }
  },
  methods: {
    select(isSelect, last) {
      if (this.noValueAndLast) {
        this.$set(this.option, 'isSelect', true);
        this.$set(this.question, 'selectValue', this.option.itemOptionsChar);
        return;
      }
      this.$set(this.option, 'isSelect', isSelect);
      if (isSelect) {
        this.$set(this.question, 'selectValue', this.option.itemOptionsChar);
      } else if (this.question.selectValue === this.option.itemOptionsChar) {
        this.$set(this.question, 'selectValue', undefined);
      }
      const data = {
        ...this.product,
        ...this.question,
        ...this.option
      };
      this.$emit('select', data, isSelect, last);
    }
  },
};
</script>

<style lang="less" scoped>
.test{
  .border{
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    overflow: hidden;
  }
  .question{
    display: flex;
    align-items: flex-start;
    img{
      height: 40px;
      margin-top: -10px;
    }
  }
  &-type{
    .border;

    display: flex;
    margin-bottom: 16px;
    &__title{
      margin-left: 17px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .title{
      font-weight: 500;
      font-size: 16px;
      color: #333;
    }
    .text{
      font-size: 14px;
      color: #888;
    }
  }
  .pic img{
    height: 100%;
  }
  &-index{
    margin-bottom: 16px;
    font-size: 14px;
    color: #BDBDBD;
  }
  &-title{
    .border;

    padding: 15px;
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 16px;
    color: #333;
    line-height: 1.5;
  }
  &-text{
    font-size: 16px;
  }
  &-video{
    height: 218px;
    line-height: 218px;
    margin-bottom: 16px;
  }
  &-answer{
    &__item{
      .border;

      margin-bottom: 16px;
      padding: 8px 16px;
      cursor: pointer;
      user-select: none;
    }
    .circle{
      height: 24px;
      line-height: 24px;
      width: 24px;
      text-align: center;
      border: 1px solid #BDBDBD;
      border-radius: 100%;
      display: inline-block;
      margin-right: 16px;
      color: white;
      &.select{
        background: #85C6CE;
        border-color: #85C6CE;
      }
    }
  }
}
</style>
